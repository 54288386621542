import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"

const NotFoundPage = ({ data }) => {
  const {
    strapi: { global, localizations },
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  const localizationKeys = data.strapi.localizationKey.keyValuePairs.reduce((pv, cv) => { pv[cv.key] = cv.value; return pv; }, {});

  return (
    <Layout
      pageContext={{
        locale: defaultLocale,
        locales,
        localizations,
        localizationKeys,
        defaultLocale,
        slug: "404",
      }}
      global={global}
    >
      <SEO title="404: Not found" />
      <div className='flex flex-col'>
        <main className='bg-primary lg:mt-4 lg:mb-32'>
          <div className="container hero-container relative flex flex-col md:flex-row items-start justify-between lg:justify-start bg-primary text-white font-100 lg:h-96">
            {/* Left column for content */}
            <div className="flex-1 lg:flex-none sm:pr-8 py-8 lg:py-20 lg:w-6/12 lg:flex-grow-0">
              {/* Hero section label */}
              <p className="uppercase tracking-wide">{data.label}</p>
              {/* Big title */}
              <h1 className="title mb-2 lg:mb-6">404 Not found</h1>
              {/* Description paragraph */}
              <h2 className="text-xl 2xl:text-2xl mb-2 lg:mb-6">We couldn't find the page you're looking for. Perhaps one of these options will help:</h2>
              <div className='flex space-x-4 text-white text-xl'>
                <Link className='hover:underline' to={'/how-it-works'}>How it works</Link>
                <Link className='hover:underline' to={'/pricing'}>Pricing</Link>
                <Link className='hover:underline' to={'/contact-us'}>Contact us</Link>
              </div>
            </div>
            {/* Right column for the image */}
            <div className='hero-img-container hidden lg:block flex-shrink-0 object-contain w-full md:w-9/12 lg:w-6/12 mt-6 md:mt-0 relative -top-4 h-full'>
              <img className='hero-img' src="/404-hero.jpg" />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapi {
      global {
        footer {
          id
          logo {
            id
            mime
            alternativeText
            url
            id
            urlSharp {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          urls {
            url
            text
            newTab
            id
          }
          smallText1
          smallText2
          smallText3
          media {
            instagram
            facebook
            twitter
            linkedIn
          }
        }
        id
        metaTitleSuffix
        metadata {
          id
          metaDescription
          metaTitle
          twitterCardType
          twitterUsername
        }
        navbar {
          button {
            id
            newTab
            text
            type
            url
          }
          id
          rightlinks {
            url
            text
            newTab
            id
          }
          leftlinks {
            url
            text
            newTab
            id
          }
          logo {
            id
            mime
            alternativeText
            url
            id
            mime
            urlSharp {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        notificationBanner {
          id
          text
          type
        }
      }
      localizationKey {
        keyValuePairs {
          id
          value
          key
        }
      }
    }
  }
`
